import Vue from 'vue';
import VueRouter from 'vue-router';
// import { getAccount } from '@/contracts/index';

const Home = () => import(/* webpackChunkName: 'home' */ '../views/HomeView.vue');
const Dashboard = () => import(/* webpackChunkName: 'dashboard' */ '../views/DashboardView.vue');
// const Borrow = () => import(/* webpackChunkName: 'borrow' */ '../views/BorrowView.vue');
const BorrowList = () => import(/* webpackChunkName: 'borrowlist' */ '../views/BorrowList.vue');
const BorrowDetails = () => import(/* webpackChunkName: 'BorrowDetails' */ '../views/BorrowDetails.vue');
const BorrowConnectView = () => import(/* webpackChunkName: 'BorrowDetails' */ '../views/BorrowConnectView.vue');
const DepositConnectView = () => import(/* webpackChunkName: 'DepositConnectView' */ '../views/DepositConnectView.vue');
const Repay = () => import(/* webpackChunkName: 'Repay' */ '../views/RepayView.vue');
const Withdraw = () => import(/* webpackChunkName: 'Repay' */ '../views/WithdrawView.vue');
const AuctionDetails = () => import(/* webpackChunkName: 'AuctionDetails' */ '../views/AuctionDetails.vue');
const MintConnectView = () => import(/* webpackChunkName: "MintConnectView" */ '../views/MintConnectView.vue');
const TransactionsView = () => import(/* webpackChunkName: "TransactionsView" */ '../views/TransactionsView.vue');
const AuctionView = () => import(/* webpackChunkName: 'AuctionView' */ '../views/AuctionView.vue');

Vue.use(VueRouter);
// async function checkAuth() {
//   const expiry = localStorage.getItem('expiry');
//   const account = await getAccount();
//   if (!account) {
//     return false;
//   }
//   if (new Date() > new Date(expiry)) {
//     return false;
//   }

//   return true;
// }

// async function isAuthenticated(to, from, next) {
//   const status = await checkAuth();
//   if (status) {
//     next();
//   } else {
//     next('/');
//   }
// }

// async function isNotAuthenticated(to, from, next) {
//   const status = await checkAuth();
//   if (!status) {
//     next();
//   } else {
//     next('/dashboard');
//   }
// }

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // beforeEnter: isNotAuthenticated,
  },
  {
    path: '/dashboard',
    // beforeEnter: isAuthenticated,
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/borrow',
    name: 'borrow',
    component: BorrowConnectView,
    // beforeEnter: isAuthenticated,
  },
  {
    path: '/borrowlist',
    name: 'borrowlist',
    component: BorrowList,
    // beforeEnter: isAuthenticated,
  },
  {
    path: '/borrow-details',
    name: 'Borroweach',
    // beforeEnter: isAuthenticated,
    component: BorrowDetails,
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: DepositConnectView,
  },
  {
    path: '/repay',
    name: 'Repay',
    component: Repay,
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: Withdraw,
    // beforeEnter: isAuthenticated,
  },
  {
    path: '/auction',
    name: 'Auction',
    component: AuctionView,
    // beforeEnter: isAuthenticated,
  },
  {
    path: '/auction-details',
    name: 'AuctionDetails',
    component: AuctionDetails,
    // beforeEnter: isAuthenticated,
  },
  {
    path: '/mock',
    name: 'Mint',
    // beforeEnter: isAuthenticated,
    component: MintConnectView,
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: TransactionsView,
    // beforeEnter: isAuthenticated,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
