<template>
  <div class="hero-bg">
    <div style="padding: 40px 0">
      <b-row>
        <b-col cols="12">
          <h2 class="heading">
            <span class="colored-title">
              Welcome
            </span>
            <br />
            <span style="font-weight: 200">
              Select one of these functions
            </span>
          </h2>
        </b-col>
        <div class="function-container">
          <img
            style=" cursor: pointer"
            src="../assets/new/add-liquidity.svg"
            alt="home image 1"
            @click="$router.push('/deposit')"
            aria-hidden="true"
          />
          <img
            style=" cursor: pointer"
            src="../assets/new/borrow-with-nft.svg"
            alt="home image 2"
            @click="$router.push('/borrow')"
            aria-hidden="true"
          />
          <img
            style="cursor: pointer"
            src="../assets/new/auction.svg"
            alt="home image 3"
            @click="$router.push('/auction')"
            aria-hidden="true"
          />
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue';

export default {
  name: 'HomePageHero',
  components: {
    BCol,
    BRow,
  },
  methods: {
    connectWallet() {
      const callback = () => {
        this.$router.push('/dashboard');
      };
      this.$store.dispatch('app/connectWallet', callback);
    },
  },
};
</script>

<style>
 .hero-bg{
    background-image: url("../assets/new/hero-bg.svg");
    background-size: contain;
  }

  .colored-title {
    background: -webkit-linear-gradient(90deg, #01CFC5 0%, #0061D3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .function-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
  }
</style>
