<template>
  <b-navbar class="bg" toggleable="lg" type="dark" variant="info">
    <b-container>
      <router-link to="/" class="navbar-brand">
        <img
          alt="Vue logo"
          style="max-height: 32px"
          src="../../assets/logo.svg"
        />
      </router-link>

      <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="nav-links custom-navs">
          <b-nav-item href="#">
            <router-link to="/deposit" class="nav-link active">
              Add Liquidity
            </router-link>
            <div
              class="nav-pseudo-bottom-border"
              :class="{'colored-border': currentRouteName === 'Deposit'}"
            ></div>
          </b-nav-item>
          <b-nav-item href="#">
            <router-link to="/mock" class="nav-link active">
              Mint Nft
            </router-link>
            <div
              class="nav-pseudo-bottom-border"
              :class="{'colored-border': currentRouteName === 'Repay'}"
            ></div>
          </b-nav-item>
          <b-nav-item href="#">
            <!-- <div class="module-border-wrap"> -->
              <router-link to="/borrow" class="nav-link active module"
              >Borrow
            </router-link>
            <div
              class="nav-pseudo-bottom-border"
              :class="{'colored-border': currentRouteName === 'borrow'}"
            ></div>
            <!-- </div> -->
          </b-nav-item>
          <b-nav-item href="#">
            <router-link to="/auction" class="nav-link active">
              Auction
            </router-link>
            <div
              class="nav-pseudo-bottom-border"
              :class="{'colored-border': currentRouteName === 'Auction'}"
            ></div>
          </b-nav-item>
          <b-nav-item href="#">
            <router-link to="/borrow" class="nav-link active">
              FAQs
            </router-link>
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
        <b-button
          size="sm"
          class="my-2 my-sm-0 connect"
          @click="onConnectWallet"
          v-if="!isConnected"
        >
          <img
            alt="Vue logo"
            style="max-height: 35px"
            src="../../assets/wallet.svg"
          />Connect Wallet
        </b-button>
        <div v-else class="profile-holder">
          <img
            alt="Vue logo"
            style="max-height: 35px"
            src="../../assets/profile.png"
          />
          <span>{{ account }}</span>
        </div>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
/* eslint-disable */ 
import {
  BContainer, BNavbar, BCollapse, BNavbarNav, BNavItem, BButton,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import {
  signMessage,
  getAccount,
  getNetworkId,
} from '@/contracts/index';
import { setApprovalForAll } from "@/contracts/NftAssetProvider";

import { approveDelegation } from "@/contracts/DebtTokenProvider";

import { borrowETH, repayETH } from "@/contracts/WETHGateway";

import { setAssetData } from "@/contracts/NftOracle";

import Api from '@/Api';

export default {
  name: 'AppHeader',
  components: {
    BNavbar,
    BCollapse,
    BNavbarNav,
    BNavItem,
    BButton,
    BContainer,
  },
  computed: {
    ...mapGetters('app', {
      isConnected: 'getIsConnected',
      account: 'getAccount',
    }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      accountData: '',
      isAlert: false,
    };
  },
  async mounted() {
    // verify session
    const token = `${localStorage.getItem('token')}`;
    if (token) {
      // eslint-disable-next-line
      Api.defaults.headers.common['Authorization'] = token;
    }
    const expiry = localStorage.getItem('expiry');
    const networkId = await getNetworkId();
    console.log('networkId', networkId);
    if (networkId !== 11155111) {
      alert('Please connect to sepolia network.');
    }
    const account = await getAccount();
    if (!account) {
      this.$store.dispatch('app/updateAccount', { isConnected: false, account: null });
      return;
    }
    if (new Date() > new Date(expiry)) {
      localStorage.removeItem('token');
      localStorage.removeItem('expiry');
      localStorage.removeItem('user');
      this.$store.dispatch('app/updateAccount', { isConnected: false, account: null });
    } else {
      this.$store.dispatch('app/updateAccount', { isConnected: true, account });
      // eslint-disable-next-line
      Api.defaults.headers.common['Authorization'] = `${localStorage.getItem(
        'token',
      )}`;
    }
    window.ethereum.on('networkChanged', function (changedNetworkId) {
      if (changedNetworkId !== '5' && !this.isAlert) {
        this.isAlert = true;
        alert('Please connect to sepolia network.');
      } else {
        this.isAlert = false;
      }
    });
    window.ethereum.on('accountsChanged', async (accounts) => {
      const changedAcc = accounts[0];
      if (changedAcc !== account) {
        localStorage.removeItem('token');
        localStorage.removeItem('expiry');
        localStorage.removeItem('user');
        // this.isConnected = false;
        this.$store.dispatch('app/updateAccount', { isConnected: false, account: null });
        window.location.reload();
      }
    });
  },
  methods: {
    onWalletConnect() {
      this.$router.push('/dashboard');
    },
    async onConnectWallet() {
      this.$store.dispatch('app/connectWallet', this.onWalletConnect);
      // const account = await getAccount();
      // const expiry = localStorage.getItem('expiry');
      // const self = this;
      // if (new Date() > new Date(expiry) && account) {
      //   const d = new Date();
      //   // Get Nonce
      //   const res = await Api.post(`/api/users/${account}/nonce`);
      //   const { signature } = await signMessage(`Nonce: ${res.data}`);
      //   const resData = await Api.post(`/api/users/${account}/login`, {
      //     signature,
      //   });
      //   localStorage.setItem('token', resData.data.token);
      //   // eslint-disable-next-line
      //   Api.defaults.headers.common['Authorization'] = `${resData.data.token}`;
      //   d.setDate(d.getDate() + 1);
      //   localStorage.setItem('expiry', d.getTime());
      //   localStorage.setItem('user', JSON.stringify(resData.data.user));
      //   self.isConnected = true;
      //   self.account = account;
      //   self.$router.push('/dashboard');
      // }
      // Borrow Eth
      // mint nft
      // setAssetData()
      // await setApprovalForAll("0xD0ff8ae7E3D9591605505D3db9C33b96c4809CDC");
      // await approveDelegation();
      //borrowETH("0.0001", "0xE15A78992dd4a9d6833eA7C9643650d3b0a2eD2B", "180","30");
      
      // eslint-disable-next-line
      // repayETH("0xE15A78992dd4a9d6833eA7C9643650d3b0a2eD2B","180", "0.000100000075605929");
      // REPAY CALL
      // getUserReservesData();
      window.ethereum.on('accountsChanged', async (accounts) => {
        try {
          const d = new Date();
          const accountChanged = accounts[0];
          // Get Nonce
          const res = await Api.post(`/api/users/${accountChanged}/nonce`);
          const { signature } = await signMessage(`Nonce: ${res.data}`);
          const resData = await Api.post(`/api/users/${accountChanged}/login`, {
            signature,
          });
          localStorage.setItem('token', resData.data.token);
          // eslint-disable-next-line
          Api.defaults.headers['Authorization'] = `${resData.data.token}`;
          d.setDate(d.getDate() + 1);
          localStorage.setItem('expiry', d.getTime());
          localStorage.setItem('user', JSON.stringify(resData.data.user));
          // self.isConnected = true;
          // self.account = accountChanged;
          this.$store.dispatch('app/updateAccount', { isConnected: true, account: accountChanged });
          this.$router.push('/dashboard');
        } catch (e) {
          console.log(e);
        }
      });
      // await connectWallet();
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  // background: transparent !important;
  background: #0C101B !important;
  max-height: 64px;
}

.nav-links {
  margin-left: auto !important;
  margin-right: auto !important;
}
.connect {
  display: flex;
  background: linear-gradient(96.57deg, #01CFC5 45.61%, #2C5EF5 138.22%);
  border-radius: 4px;
  padding: 8px 8px;
  color: #0C101B;
  font-size: 16px;
  font-weight: 600;
  img {
    margin-right: 10px;
  }
}

.nav-link {
  font-weight: 600;
  color: #F5F5F5;
  font-size: 14px;
}

nav {
    padding:5px 30px;
}
.navbar {
  border-bottom: 1px solid #056277;
}

.profile-holder {
  background: #056277;
  border-radius: 4px;
  color: #F5F5F5;
  padding: 8px;
  img {
    margin-right: 10px;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    max-width: 82px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.nav-pseudo-bottom-border {
  height: 4px;
  border-radius: 2px;
}

.colored-border.nav-pseudo-bottom-border {
  background: linear-gradient(90deg, #01CDC4 0%, rgba(44, 94, 245, 0.4));

}
</style>
