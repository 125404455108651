/* eslint-disable */
// https://github.com/BendDAO/bend-lending-protocol/blob/main/contracts/protocol/WETHGateway.sol
import * as Web3 from "web3";
import { getAccount } from "./index";

export let web3Provider =
  window.web3 && window.web3.currentProvider
    ? window.web3.currentProvider
    : new Web3.providers.HttpProvider(
        "https://sepolia.infura.io/v3/10b69427a8cf4a318c0ddbc0b71b57f8"
      );

// export const WETHGateway = '0x21950c9b7F35c15C2F7CeDa564206c425acB54Fd';
import { WETHGateway } from "./CONTRACTS";
const networkCallbacks = [];
import { WETH_ABI, LENDPOOLLOANABI } from "./ABI_CONSTANT";

export async function depositETH(amount) {
  debugger;
  return new Promise(async (resolve, reject) => {
    // await window.ethereum.enable();
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    //let meta = this;
    let contractAddress = WETHGateway;
    var contractInstance = new window.web3.eth.Contract(WETH_ABI, contractAddress);
    // contractInstance.methods.isCallerInWhitelist(account).call(function (error, data) {
    //   console.log("isCallerInWhitelist ", data);
    // });
    // contractInstance.methods.getWETHAddress().call(function (error, data) {
    //   console.log("getWETHAddress ", data);
    // });
    // contractInstance.methods.owner().call(function (error, data) {
    //   console.log("owner ", data);
    // });
    contractInstance.methods
      .depositETH(account, 0)
      .send(
        { from: account, value: window.web3.utils.toWei(amount + "", "ether") },
        function (error, transactionHash) {
          console.log("transactionHash", transactionHash);
        }
      )
      .on("error", function (error) {
        console.log(error);
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        resolve(receipt["status"]);
      });

    // let address = [];
    // address.push("0x9F5101271F10592E0655C72fda5e70e28af9c925");
    // contractInstance.methods
    //   .authorizeCallerWhitelist(address, true)
    //   .send({ from: account }, function (error, transactionHash) {
    //     console.log("authorizeCallerWhitelist", transactionHash);
    //   })
    //   .on("error", function (error) {
    //     console.log(error);
    //     reject(error);
    //   })
    //   .on("authorizeCallerWhitelist", function (transactionHash) {
    //     console.log("authorizeCallerWhitelist", transactionHash);
    //   })
    //   .on("receipt", function (receipt) {
    //     console.log(receipt);
    //     resolve(receipt["status"]);
    //   });
  });
}

export async function withdrawETH(amount) {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    //let meta = this;
    let contractAddress = WETHGateway;
    var contractInstance = new window.web3.eth.Contract(WETH_ABI, contractAddress);
    contractInstance.methods
      .withdrawETH(window.web3.utils.toWei(amount + "", "ether"), account)
      .send({ from: account }, function (error, transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("error", function (error) {
        console.log(error);
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        resolve(receipt["status"]);
      });
  });
}

export async function borrowETH(amount, nftAsset, nftTokenId, days) {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);

    //0xC65EfD874d82bAd5C9218f4476748a275ce083B2
    //let meta = this;
    // var contractInstance = new window.web3.eth.Contract(
    //   LENDPOOLLOANABI,
    //   "0xC65EfD874d82bAd5C9218f4476748a275ce083B2"
    // );

    // contractInstance.methods
    //   .getCollateralLoanId("0x48b23162373720F96F5E72bFCE4aacA0130Ace29", "2")
    //   .call(function (error, data) {
    //     if (error) {
    //       return reject();
    //     }
    //     console.log("getCollateralLoanId ", data);
    //   });

    let contractAddress = WETHGateway;
    var contractInstance = new window.web3.eth.Contract(WETH_ABI, contractAddress);
    amount = amount + "";
    contractInstance.methods
      .borrowETH(window.web3.utils.toWei(amount, "ether"), nftAsset, nftTokenId, account, days, 0)
      .send({ from: account }, function (error, transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("error", function (error) {
        console.log(error);
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        resolve(receipt["status"]);
      });
  });
}

export async function repayETH(nftAsset, nftTokenId, amount) {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    //let meta = this;
    let contractAddress = WETHGateway;
    var contractInstance = new window.web3.eth.Contract(WETH_ABI, contractAddress);
    contractInstance.methods
      .repayETH(nftAsset, nftTokenId, window.web3.utils.toWei(amount, "ether"))
      .send(
        { from: account, value: window.web3.utils.toWei(amount + "", "ether") },
        function (error, transactionHash) {
          console.log("transactionHash", transactionHash);
        }
      )
      .on("error", function (error) {
        console.log(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        resolve(receipt["status"]);
      });
  });
}

export async function auctionETH(amount, nftAsset, nftTokenId) {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    //let meta = this;
    let contractAddress = WETHGateway;
    var contractInstance = new window.web3.eth.Contract(WETH_ABI, contractAddress);
    amount = amount + "";
    contractInstance.methods
      .auctionETH(nftAsset, nftTokenId, account)
      .send(
        { from: account, value: window.web3.utils.toWei(amount, "ether") },
        function (error, transactionHash) {
          console.log("transactionHash", transactionHash);
        }
      )
      .on("error", function (error) {
        console.log(error);
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        resolve(receipt["status"]);
      });
  });
}

export async function liquidateETH(nftAsset, nftTokenId) {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    //let meta = this;
    let contractAddress = WETHGateway;
    var contractInstance = new window.web3.eth.Contract(WETH_ABI, contractAddress);
    contractInstance.methods
      .liquidateETH(nftAsset, nftTokenId)
      .send({ from: account }, function (error, transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("error", function (error) {
        console.log(error);
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        resolve(receipt["status"]);
      });
  });
}

export async function redeemETH(nftAsset, nftTokenId, amount, bidFine) {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    //let meta = this;
    let contractAddress = WETHGateway;
    var contractInstance = new window.web3.eth.Contract(WETH_ABI, contractAddress);
    amount = window.web3.utils.toWei(amount + "", "ether");
    bidFine = window.web3.utils.toWei(bidFine + "", "ether");
    debugger;
    contractInstance.methods
      .redeemETH(nftAsset, nftTokenId, amount, bidFine)
      .send({ from: account }, function (error, transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("error", function (error) {
        console.log(error);
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        resolve(receipt["status"]);
      });
  });
}
