/* eslint-disable */
export const WETH = "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14";
export const MintableERC20 = "0xE0a3965cd1aBe8f4Fc6e1154BFA9a33791B769de";
export const DAI = "0xfF3fbe51A7c487d716430120E97D8F1646759037";
export const USDC = "0xE0a3965cd1aBe8f4Fc6e1154BFA9a33791B769de";
export const CryptoPunksMarket = "0x5Ed4d8F9c8EfeA6917852292c121F96E4Cb0F448";
export const WrappedPunk = "0x3Ea9cf971Dae12b6031cd7f2c9ee384438cAcFAa";
export const WPUNKS = "0x3Ea9cf971Dae12b6031cd7f2c9ee384438cAcFAa";
export const MintableERC721 = "0x2d37eeDb96E8f00E469BbB170f8fBb67b695Ab8c";
export const BAYC = "0xE15A78992dd4a9d6833eA7C9643650d3b0a2eD2B";
export const DOODLE = "0x06d77c69d1705C3d001f16Bc1eb73882950101D0";
export const COOL = "0x14b12086eBFfe41Dd18861c299EaA57e15fc79a2";
export const MEEBITS = "0x6320047Ce4ed417A71325771e61fb9bE0eFf5B06";
export const SDOODLE = "0x5c20Ff6990fd588A2c2E25Ba6F0762232E3E6993";
export const MAYC = "0xD0ff8ae7E3D9591605505D3db9C33b96c4809CDC";
export const WOW = "0x2d37eeDb96E8f00E469BbB170f8fBb67b695Ab8c";
export const UnicredProxyAdminPool = "0xa49A477334D578c8ddE1861ABbBD128c9036C0c9";
export const BNFT = "0x9A2202167d351A153dcD143882c961E057d130ff";
export const BNFTRegistryImpl = "0x66C6791F878dDddb5392453a3e8b31995f30e633";
export const BNFTRegistry = "0x53Ff50314bA3a244C9914FA2B006f2e528566546";
export const MockIncentivesController = "0xc81101aeeFa6032949D5ec2fe35a0b5B59C6B681";
export const UnicredProxyAdminFund = "0xC111c17b1402682eE631106Cd0Fd01Ab63BcB420";
export const LendPoolAddressesProvider = "0x64809F1C4587cCa4935C5DED602C878a096C7bfD";
export const LendPoolAddressesProviderRegistry = "0x15D7C440386b670819A62b929513495B4d78b6f7";
export const GenericLogic = "0xE284F8fe5Acb1541e224C9aA946BF4f0e51e9970";
export const ReserveLogic = "0x22bF7B850626bCc0218660C35D823D6884935C72";
export const NftLogic = "0x8A8022cB19ab2A689cDB89dc675Ab73cBaC61871";
export const ValidationLogic = "0x65F7cDA03806281b7ff9CFb680E16974723343Ea";
export const SupplyLogic = "0x3f68F5ad84806E3538C9428f470C69B3F9fe6999";
export const BorrowLogic = "0x0eD18d0aA4A12393C60C03ad3B7E69A51AF6C615";
export const LiquidateLogic = "0x5A7bbEe9f883170Cf6DB885F724586cC9204EA9d";
export const ConfiguratorLogic = "0xdA88E48ba7E5e1AaB33DDdEc3edb914dE64d5202";
export const LendPoolImpl = "0xE14bbceacbBd6531EB88a033C882f9c6e8be61e4";
export const LendPool = "0x9307F084D4FAf15554A6aBa6bd0E46fE7079d9ac";
export const LendPoolLoanImpl = "0x15c75829703Ddc4ba6d39EA671B06237A31C8dBA";
export const LendPoolLoan = "0xC65EfD874d82bAd5C9218f4476748a275ce083B2";
export const LendPoolConfiguratorImpl = "0xeAFf5Cf21C306167523c0fa5A04A0Ed955be4c9F";
export const LendPoolConfigurator = "0x11c9f3b02ABB8f7AfEf1330B737116114F953bb0";
export const BToken = "0x5e5053B2A6f655e40F2B5400d094eefD6601Ba85";
export const DebtToken = "0x5213AE80170Fe3973a8d22d919993169e2Ddae7b";
export const ReserveOracleImpl = "0x8A6633275904090E905Df71e3D95592Ad8506F6b";
export const ReserveOracle = "0xf1315B80df0860F6FFfF0aE540E15181B10b85dd";
export const NFTOracleImpl = "0x221310105ac8bB36087ae1A7BC2aFa2f99424F87";
export const NFTOracle = "0x06da7E024928867549e5A2EbF2811Bf74FcdA6f9";
export const InterestRate = "0x669ced44d1b34b654A7fA33E8535909366426137";
export const WETHGatewayImpl = "0x128826884ED911b026454EB774AFE93B629bffA1";
export const WETHGateway = "0xFfDb003aE65DD178Fb445888689eF7B6112D9785";
export const PunkGatewayImpl = "0x33504f9b186fd7dd68fa2C098ACB27543b39343C";
export const PunkGateway = "0x6d8f2Ca1777ebfCD950897c1DE4aD283fa6e05AC";
export const WalletBalanceProvider = "0xdbF4D48506735e4a64E573034b53eA3c3fD08e39";

export const UIPoolDataProvider = "0x7F56701E8AB9fA941596a33c00D16F7646C174b7";
export const UnicredProtocolDataProvider = "0x525E4a6645cb10080460121eed600b0D145593E9";
export const DEBTTOKENWETH = "0xfdcC0C021299e13fEFCC934c3434Eb11E45cdA26";
export const BTOKENWETH = "0xddf28fc6911A3F6B54e4beE1c3672cC3414Eb5c2";

export const WETHMocked = "0xaD1908f909B5C5D2B1032a215d611773F26f089F";
export const CLONEX = "0xC7354c551302294bf24B4e93AC18392F6b76130D";
export const AZUKI = "0xb8d4EA66C5a7a4225D044008C3b265b127091353";
export const KONGZ = "0x877f8a38e5e91AB00Eae4689A18141e2b1695d28";
export const rateStrategyWETH = "0x3e85AB19281F1717ada9670e8d3D5C32EB35Ea19";
export const rateStrategyStableTwo = "0x15E297A56b00dAEF8F9108EE6a014911d8Fb2f70";
export const rateStrategyStableThree = "0xa4F2318cD41B4CeaEB89a6A1C9E50b2e49444Cc2";
export const Deployer = "0xEBAcF61EeeC3486DE2eb9f200EAAbDf64Ec1F6be";
export const PoolAdmin = "0xEBAcF61EeeC3486DE2eb9f200EAAbDf64Ec1F6be";
export const EmergencyAdmin = "0xEBAcF61EeeC3486DE2eb9f200EAAbDf64Ec1F6be";
export const BendCollectorImpl = "0x0c3D91bD664D20b2d3E1af5a48Dadb060EFf913A";
export const BendCollector = "0x7A02EE743Aadca63d60945971B7eD12c7f26b6d2";
export const NWETHAggregator = "0x590328845A6dbAe96ef618b07552e5a62B93DEad";
// @important needed but missing
export const DebtToken2 = "0x76f2a9c980d98f13f7802f04d47a936d5abfbf91";
export const BendProxyAdminPool = "0xd7Daa2E92Ae9d154e037C70531Ded9B081789C69";
export const BendProxyAdminFund = "0x423C478CeeE028C6b1526871cA5D579327444bEb";
export const BendProtocolDataProvider = "0x9329487AC7C688ba29655b9b77C5C1Bf7B90620F";
