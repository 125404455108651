/* eslint-disable */
import WETHABI from "./abis/WETHGateway.json";
import DEBT_TOKEN_ABI from "./abis/DebtToken.json";
import B_TOKEN_ABI from "./abis/BToken.json";
import LENDPOOL_LOAN_ABI from "./abis/LendPoolLoan.json";
import LENDPOOL_ABI from "./abis/LendPool.json";
import UIDATAPOOLPROVIDE from "./abis/UiPoolDataProvider.json";
import WALLETBALANCEPROVIDER from "./abis/WalletBalanceProvider.json";
import MintableERC20 from "./abis/IERC20Detailed.json";
import ERC721Detailed from "./abis/ERC721Detailed.json";
import NftOracle from "./abis/NFTOracle.json";
import UnicredProtocolDataProvider from "./abis/UnicredProtocolDataProvider.json";

export const WETH_ABI = WETHABI;

export const DEBT_ABI = DEBT_TOKEN_ABI;
export const BTOKEN_ABI = B_TOKEN_ABI;

export const LENDPOOLLOANABI = LENDPOOL_LOAN_ABI;
export const LEND_POOL_ABI = LENDPOOL_ABI;
export const UIDATAPOOLPROVIDE_ABI = UIDATAPOOLPROVIDE;
export const WALLETBALANCEPROVIDER_ABI = WALLETBALANCEPROVIDER;
export const MintableERC20_ABI = MintableERC20;
export const ERC721Detailed_ABI = ERC721Detailed;
export const NftOracle_ABI = NftOracle;
export const UnicredProtocolDataProvider_ABI = UnicredProtocolDataProvider;
