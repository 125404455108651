/* eslint-disable */
import { NFTOracle } from "./CONTRACTS";
import { NftOracle_ABI } from "./ABI_CONSTANT";
import { getAccount } from "./index";
import * as Web3 from "web3";

export let web3Provider =
  window.web3 && window.web3.currentProvider
    ? window.web3.currentProvider
    : new Web3.providers.HttpProvider(
        "https://sepolia.infura.io/v3/10b69427a8cf4a318c0ddbc0b71b57f8"
      );

export async function getAssetPrice(assetContractAddress) {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    var contractInstance = new window.web3.eth.Contract(NftOracle_ABI, NFTOracle);
    contractInstance.methods
      .getAssetPrice(assetContractAddress)
      .call({ from: account }, function (error, data) {
        if (data) {
          return resolve(data);
        }
        return reject(error);
      });
  });
}
export async function setAssetData() {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    var contractInstance = new window.web3.eth.Contract(NftOracle_ABI, NFTOracle);
    contractInstance.methods
      .setAssetData(
        "0xD0ff8ae7E3D9591605505D3db9C33b96c4809CDC",
        window.web3.utils.toWei("0.001", "ether")
      )
      .send({ from: account }, function (error, data) {
        if (data) {
          return resolve(data);
        }
        return reject(error);
      });
  });
}
