/* eslint-disable */
// https://github.com/BendDAO/bend-lending-protocol/blob/main/contracts/misc/UiPoolDataProvider.sol
import * as Web3 from "web3";
import { getAccount } from "./index";
import { ERC721Detailed_ABI } from "./ABI_CONSTANT";

export let web3Provider =
  window.web3 && window.web3.currentProvider
    ? window.web3.currentProvider
    : new Web3.providers.HttpProvider(
        "https://sepolia.infura.io/v3/10b69427a8cf4a318c0ddbc0b71b57f8"
      );

// export const NFTAssetProvider = '0x10cACFfBf3Cdcfb365FDdC4795079417768BaA74';
// const DOODLE_NFT = '0x10cACFfBf3Cdcfb365FDdC4795079417768BaA74';
// const MAYC_NFT = '';
const networkCallbacks = [];
import { WETHGateway } from "./CONTRACTS";
// ERC721Detailed.json
// const ABI =

export async function setApprovalForAll(assetContractAddress) {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    //let meta = this;
    let contractAddress = assetContractAddress;
    var contractInstance = new window.web3.eth.Contract(ERC721Detailed_ABI, contractAddress);
    contractInstance.methods
      .setApprovalForAll(WETHGateway, true)
      .send({ from: account }, function (error, transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("error", function (error) {
        console.log(error);
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        resolve(receipt["status"]);
      });
  });
}

export async function isApprovalForAll(assetContractAddress) {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    //let meta = this;
    let contractAddress = assetContractAddress;
    var contractInstance = new window.web3.eth.Contract(ERC721Detailed_ABI, contractAddress);
    contractInstance.methods
      .isApprovedForAll(account, WETHGateway)
      .call({ from: account }, function (error, data) {
        debugger;
        if (data && data !== "0") {
          return resolve(true);
        }
        return resolve(false);
      });
  });
}
