/* eslint-disable */
// https://github.com/BendDAO/bend-lending-protocol/blob/main/contracts/misc/UiPoolDataProvider.sol
import * as Web3 from "web3";
import { getAccount } from "./index";

export let web3Provider =
  window.web3 && window.web3.currentProvider
    ? window.web3.currentProvider
    : new Web3.providers.HttpProvider(
        "https://sepolia.infura.io/v3/10b69427a8cf4a318c0ddbc0b71b57f8"
      );

const networkCallbacks = [];
import { DEBT_ABI } from "./ABI_CONSTANT";
import { WETHGateway, BTOKENWETH, DEBTTOKENWETH } from "./CONTRACTS";

export async function approveDelegation(nftTokenId) {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    //let meta = this;
    let contractAddress = DEBTTOKENWETH;
    var contractInstance = new window.web3.eth.Contract(DEBT_ABI, contractAddress);
    contractInstance.methods
      .approveDelegation(
        WETHGateway,
        "57896044618658097711785492504343953926634992332820282019728792003956564819968"
      )
      .send({ from: account }, function (error, transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("error", function (error) {
        console.log(error);
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        resolve(receipt["status"]);
      });
  });
}

export async function borrowAllowance() {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    //let meta = this;
    let contractAddress = DEBTTOKENWETH;
    var contractInstance = new window.web3.eth.Contract(DEBT_ABI, contractAddress);
    console.log(WETHGateway);
    contractInstance.methods
      .borrowAllowance(account, WETHGateway)
      .call({ from: account }, function (error, data) {
        debugger;
        if (data && data !== "0") {
          return resolve(true);
        }

        return resolve(false);
      });
  });
}

export async function approveWithdraw() {
  return new Promise(async (resolve, reject) => {
    const account = await getAccount();
    console.log(account);

    if (!account) {
      throw new Error("No account found");
    }

    window.web3 = new Web3(window.ethereum);
    //let meta = this;
    let contractAddress = BTOKENWETH;
    var contractInstance = new window.web3.eth.Contract(DEBT_ABI, contractAddress);
    contractInstance.methods
      .approve(
        WETHGateway,
        "57896044618658097711785492504343953926634992332820282019728792003956564819968"
      )
      .send({ from: account }, function (error, transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("error", function (error) {
        console.log(error);
        reject(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("transactionHash", transactionHash);
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
        resolve(receipt["status"]);
      });
  });
}
