<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<template>
  <div class="sidemenu-container">
    <div class="text-center">
      <img class="sidemenu-icon" src="../../assets/icons/sidemenu-hamburger.svg" alt=""/>
    </div>
    <nav>
      <ul>
        <li class="nav-link-container" :class="{active: currentRouteName === 'Home'}">
          <a href="#">
            <router-link to="/" >
              <img class="sidemenu-icon" src="../../assets/icons/home-sidemenu.svg" alt=""/>
            </router-link>
          </a>
        </li>
        <li class="nav-link-container" :class="{active: currentRouteName === 'Dashboard'}">
          <a href="#">
            <router-link to="/dashboard" >
              <img class="sidemenu-icon" src="../../assets/icons/dashboard-sidemenu.svg" alt=""/>
            </router-link>
          </a>
        </li>
        <li class="nav-link-container" :class="{active: currentRouteName === 'Transactions'}">
          <a href="#">
            <router-link to="/transactions" >
              <img class="sidemenu-icon" src="../../assets/icons/transaction-sidemenu.svg" alt=""/>
            </router-link>
          </a>
        </li>
      </ul>
    </nav>
  </div>

</template>

<script>
export default {
  name: 'AppSideBar',
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

};
</script>

<style scoped lang="scss">
  .sidemenu-container {
    display: flex;
    flex-direction: column;
    margin: 32px 0 0 32px;
    row-gap: 56px;
  }

  .text-center {
    text-align: center;
  }

  nav {
    padding: 0;
    margin: 0;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
  .nav-link-container {
    padding: 8px 0;
    text-align: center;

  }
  .sidemenu-icon {
    height: 32px;
    width: 32px;
  }

  .nav-link-container.active {
    background: linear-gradient(
      100.78deg, rgba(0, 255, 225, 0.72) -21.77%, rgba(0, 97, 211, 0.72) 102.79%
      );
    border-radius: 8px 0 0 8px;
  }
</style>
