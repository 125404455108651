<template>
  <div>
    <div class="bg">
      <Header />
    </div>
    <div class="main-container">
      <template v-if="currentRouteName === 'Home'">
        <HomePageHero />
      </template>

      <Content>
        <template v-slot:content>
          <div class="content-container">
            <template v-if="!hideSideBar">
              <div class="sidebar">
                <Sidebar />
              </div>
            </template>
            <div :class="hideSideBar ? 'main-content-without-sidebar' : 'main-content'">
              <router-view />
            </div>
          </div>
        </template>
      </Content>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Header from '@/components/Layout/Header.vue';
import Sidebar from '@/components/Layout/Sidebar.vue';
import Content from '@/components/Layout/Content.vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Api from '@/Api';
import HomePageHero from './components/HomePageHero.vue';

Vue.use(VueToast);
export default {
  components: {
    Header,
    Content,
    Sidebar,
    HomePageHero,
  },
  async mounted() {
    try {
      const data = await Api.get('/api/collections');
      // debugger;
      this.$store.dispatch('app/storeCollections', data.data);
    } catch (e) {
      this.$toast.open({
        message: 'Something went wrong while fetching collections!',
        type: 'error',
        position: 'top-right',
        duration: 10000,
        // all of other options may go here
      });
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    hideSideBar() {
      const routesToHideSideBarOn = [
        'Repay',
        'Borroweach',
        'AuctionDetails',
      ];

      const shouldHideSideBar = routesToHideSideBarOn.includes(this.$route.name);
      return shouldHideSideBar;
    },
  },
};
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background: rgba(12, 16, 27, 1) !important;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.bg {
  background: linear-gradient(180deg, #0b101a 0%, #046277 100%);
}

.content-container {
  display: flex;
}

.sidebar {
  width: 128px;
  min-height: calc(100vh - 64px);
}

.main-content {
  width: calc(100% - 128px);
}

.main-content-without-sidebar {
  width: 100%;
}

.heading {
  font-weight: 700;
  font-size: 48px;
  line-height: 50px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0 0 40px;
}

.sub-heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 40px;
}

.main-hold {
  border: 1px solid #0c101b;
  min-height: 100vh;
}

.each {
  padding: 40px;
  border-bottom: 1px solid #0c101b;

  h3 {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
}

// @media (min-width: 1400px) {
//   .container {
//     max-width: 1100px;
//   }
// }

.flex {
  display: flex;
  justify-content: center;
}

.gap-4px {
  gap: 4px
}

.gap-8 {
  gap: 8px
}

.gap-16 {
  gap: 16px
}

.tabs {
  padding-top: 32px;
}

.nav-pills {
  padding-bottom: 37px !important;

  .nav-item a {
    padding: 16px 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    border-radius: 0px;
    color: #9AADB9;
    border-bottom: 1px solid #9AADB94D;
  }

  .nav-item a.active {
    background: #0C101B;
    border-bottom: 2px solid #ffff;
    font-weight: 600;
    color: #fff !important;
  }
}

.tabs-container {
  .tabs {
    padding-top: 0;
  }

  .nav-pills {
    border-radius: 0 !important;

    .nav-item a {
      border-radius: 4px;
      color: #9AADB9;
    }
  }

  .nav-link {
    border-radius: 0 !important;
    border-bottom: 1px solid #9AADB94D !important;
  }

  .nav-link.active {
    border-bottom: 2px solid #fff !important;
  }
}

.custom-navs {
  column-gap: 16px;
  .nav-link.active {
    color: #C7EFF7;
  }
}

.btn-active {
  background: #151A24 !important;
  color: #fff !important;
  display: block !important;
  width: 100% !important;
  padding: 20px !important;
}

.btn-loading {
  @extend .btn-active;
  background: rgba(1, 230, 211, 0.1) !important;
  color: #01E6D3 !important;
  border: 2px solid #01E6D3 !important;
}

.btn-inactive {
  background: rgb(255, 255, 255) !important;
  color: rgb(120, 120, 120) !important;
  display: block !important;
  width: 100% !important;
  padding: 20px !important;
  border-color: rgb(120, 120, 120) !important;
}

.v-toast__item--success {
  // background: rgba(1, 230, 211, 0.1);
  // border: 2px solid #01E6D3;
  // color: #056277;
  background: #5FCE8B33;
  backdrop-filter: blur(4px);
  border: 1px solid #5FCE8B;
  color: #5FCE8B
}

.v-toast__item .v-toast__text {
  padding: 0.5em;
}

.v-toast__item.v-toast__item--success .v-toast__icon {
  background: url('~@/assets/tick.svg') no-repeat;
}

.v-toast__item {
  min-height: 50px;
}

.v-toast__item--error {
  // background-color: #f7471c;
  // background: #FFFAFA;
  // border: 1px solid #FF0000;
  // color: #0C101B;

  backdrop-filter: blur(4px);
  background: #DF2E2E1F;
  border: 1px solid #DF2E2E;
  color: #DF2E2E;

}

.v-toast__item.v-toast__item--error .v-toast__icon {
  background: url('~@/assets/error.svg') no-repeat;
}

.healthy {
  color: #FFFFFF !important;
}

.un-healthy {
  color: #FF4004 !important;
}

.connected-page-title {
  font-size: 32px;
  color: #C7EFF7;
  font-weight: 600;
  line-height: 1;
  text-align: left;
}

.color-01CDC4 {
  color: #01CDC4 !important;
}

.color-fafafa {
  color: #fafafa !important;
}
.color-C7EFF7{
  color:#C7EFF7 !important
}

.color-FF3D00{
  color:#FF3D00 !important
}
.color-9AADB9{
  color:#9AADB9 !important
}

.font-600 {
  font-weight: 600 !important;
}
.font-500 {
  font-weight: 500 !important;
}

.border-gradient {
  border: 1px solid transparent;
  border-radius: 8px;
  background:
    linear-gradient(to right, #0C1F30, #0C1F30),
    linear-gradient(180deg, #00728F 0%, #007CC2 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}
.font-12{
  font-size: 12px !important;
}
.font-16{
  font-size: 16px !important;
}
.font-18{
  font-size: 18px !important;
}
.table thead {
  border: 1px solid #000000;
  border-radius: 4px;
}
.table th {
  background: #00FFE114 !important;
  font-weight: 700;
  font-size: 16px;
  padding: 14px !important;
  text-align: left !important;
  padding-top: 14px;
  padding-bottom: 18px;
  text-align: center;
  color: #FAFAFA;
}

.table td {
  text-align: left !important;
  font-size: 16px;
  color: #FAFAFA;
  padding: 16px !important;
  vertical-align: middle;
  border: 0;
  background: #020025 !important;
  border-bottom: 1px solid #2A5166;
  b, strong {
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
    line-height: 1;
  }
}
.transparent-bg-btn {
  background: transparent;
  color: rgb(120, 120, 120) !important;
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  padding: 20px;
  border-color: #899390 !important;
  height: 66px;
}
.active-btn-bg{
  background: #01cdc4 !important;
  color: #020025;
  display: block;
  width: 100%;
  padding: 20px;
  height: 66px;
}

.custom-select {
  -webkit-appearance: none;
  background-image: url(./assets/chevron-down.svg) !important;
  background-position: right 6px center !important;
  background-repeat: no-repeat !important;
  background-size: 60px 28px !important;
  border: 1px solid #2A5166 !important;
  height: calc(1.5em + .75rem + 8px);
  background-color: transparent !important;
  height: 70px;
  color: #fff !important;
  padding: 0.375rem 51px 0.375rem 0.75rem;
}

.td-padding-left-12 {
  padding-left: 12px;
}

.td-inline {
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
  line-height: 1;
}

.td-nft-img {
  width: 40px;
  height: 40px;
}

.vue-slider-marks{
  color: #fff !important;
}
.vue-slider-rail {
  background-color: #fff !important;
}

// know more text hide and show
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in versions <2.1.8 */ {
  opacity: 0;
}
</style>
